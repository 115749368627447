import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import Container from "../../components/Container";
import Seo from "../../components/seo";
import HeroLanding from "../../components/HeroLanding";
import ProductModal from "../../components/ProductModal";
import SecondaryButton from "../../components/SecondaryButton";
import CardColor from "../../components/CardColor";
import blueIcon from "../../images/icons/blue.png";
import greenIcon from "../../images/icons/green.png";
import Header from "../../components/Header";
import OrangeIcon from "../../images/icons/orange.png";
import yellowIcon from "../../images/icons/yellow.png";
import FundInsightsScreen from "../../images/fund-insight/fund-insights-screen.mp4";
import HeroVideo from "../../images/fund-insight/hero-video.mp4";
import distribution1Img from "../../images/fund-insight/fi-banner-1.png";
import distribution2Img from "../../images/fund-insight/fi-banner-2.png";
import distribution3Img from "../../images/fund-insight/fi-banner-3.png";
import videoIcon from "../../images/fund-insight/video-icon.svg";
import pictureIcon from "../../images/fund-insight/picture-icon.svg";
import musicFileIcon from "../../images/fund-insight/music-file-icon.svg";
import fileIcon from "../../images/fund-insight/file-icon.svg";
import { theme } from "../../themeV2";

const metadata = {
  title: "Fund Insight",
  description: "Fund Insight description",
};

const toolsItems = [
  {
      icon: videoIcon,
      title: (
        <Trans>
          Video
        </Trans>
      ),
    },
    {
      icon: pictureIcon,
      title: (
        <Trans>
          Images
        </Trans>
      ),
    },
    {
      icon: musicFileIcon,
      title: (
        <Trans>
          Music
        </Trans>
      ),
    },
    {
      icon: fileIcon,
      title: (
        <Trans>
          File
        </Trans>
      ),
    },
    ];

const itemCards = [
    {
        icon: yellowIcon,
        color: theme.palette.colors.yellow.main,
        title: (
          <Trans>
            Reinforce
          </Trans>
        ),
        description: (
          <Trans>
            Enhance sales efforts with in-depth insights
          </Trans>
        ),
      },
      {
        icon: OrangeIcon,
        color: theme.palette.colors.orange.dark,
        title: <Trans>Reach</Trans>,
        description: (
          <Trans>
            Reach potential clients
          </Trans>
        ),
      },
      {
        icon: greenIcon,
        color: theme.palette.colors.green.main,
        title: <Trans>Build</Trans>,
        description: (
          <Trans>
            Build loyalty with existing clients
          </Trans>
        ),
      },
      {
        icon: blueIcon,
        color: theme.palette.colors.blue.light,
        title: <Trans>Differentiate</Trans>,
        description: (
          <Trans>
            Differentiate your offering in a highly competitive market
          </Trans>
        ),
      },
];

const distributionItems = [
  {
    image: distribution1Img,
    description: (
      <Trans>
        Our intuitive, simple to use and fast content editor produces engaging content while saving you time
      </Trans>
    ),
  },
  {
    image: distribution2Img,
    description: (
      <Trans>
        Enrich content with other documents, images, video and audio files with a simple drag and drop.
      </Trans>
    ),
  },
  {
    image: distribution3Img,
    description: (
      <Trans>
        Allow potential investors to reach you with one click due to clever company and user account setup.
      </Trans>
    ),
    },
];

const requestInfoSubject = "Invesment Solution - Due Diligence Portal";

function DueDiligenceHome() {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleOnclickInfo = () => {
    setShowModal(true);
  };

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderItem = ({ title, description, color, icon }) => {
    return (
      <CardColor color={color} icon={icon} title={title} description={description} />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Seo title={metadata.title} description={metadata.description} />
      <CssBaseline />
      <Header />
      <HeroLanding
        title={
          <Trans>Bring your funds closer to the decision makers</Trans>
          }
        description={
          <Trans>
            Publish everything from opinions and
            market intelligence to reports and research, bringing the narrative directly to decision making investment professionals such as fund selectors and advisors.
          </Trans>
          }
        handleOnclickInfo={() => handleOnclickInfo()}
        multimedia={HeroVideo}
        multimediaSide="left"
        kind="Fund Insights"
        grid={
          {columnRight: {
            md: 5,
          },
          columnLeft: {
            md: 7,
          }}
        }
        kindColor={theme.palette.colors.green.main}
        isVideo={true}
        />
      <Box position="relative" overflow="hidden" pb={{ xs: 3, sm: 0 }} pt={{ xs: 3, sm: 1, lg: 0 }}>
        <Container>
          <Grid container pb={{ xs: 6, md: 2 }} spacing={4} sx={{ width: "100%", position: { xs: "relative", md: "absolute" }, zIndex: 1 }} height="100%" display="flex" alignItems="center">
            <Grid item xs={12} md={5} lg={4}>
              <Stack spacing={1}>
                <Typography fontWeight={700} fontSize={20} color={theme.palette.colors.green.main}>
                  <Trans>Events & Webinars</Trans>
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="h3">
                    <Trans>Connect and engage</Trans>
                  </Typography>
                  <Typography>
                    <Trans>
                      Discover and sign up for events and webinars directly through Fund Insights. Stay connected with industry experts, participate in discussions, and gain valuable insights from professionals shaping the investment landscape.
                    </Trans>
                  </Typography>
                  <Box display="flex" justifyContent={{ xs: "center", lg: "start" }}>
                    {renderRequestButton()}
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Box sx={{ position: { md: "relative" }, width: "100%", height: { xs: "auto", md: "100%" } }}>
          <Box display="flex" justifyContent="flex-end" height="100%">
            <Box width={{ xs: "100%", md: "70%"}}>
              <video muted autoPlay playsInline style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                <source src={FundInsightsScreen} type="video/mp4" />
              </video>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box pt={{ xs: 5, sm: 9 }} pb={{ xs: 5, sm: 6 }} sx={{ backgroundColor: theme.palette.colors.grey.light }} display="flex" justifyContent="center">
        <Container>
          <Grid container pb={0.5} justifyContent="center">
            <Typography mb={{ xs: 2, lg: 0 }} variant="h4" textAlign="center">
              <Trans>
                Streamline content distribution with an
              </Trans>
              {isTablet ? <br /> : " "}
              <Trans>
                <span style={{color: theme.palette.colors.green.main}}>
                  easy to use tool
                </span>
              </Trans>
            </Typography>
          </Grid>
          <Grid container spacing={{xs: 7.5, sm: 3}} alignItems="center">
            {distributionItems.map(({ image, description }) => (
              <Grid item xs={12} md={4} lg={4}>
                <Stack direction={{ xs: "column", sm: "row", md: "column" }} alignItems="center">
                  <Box>
                    <img width="100%" alt="enhance" src={image} />
                  </Box>
                  <Typography mt={-2} color={theme.palette.colors.blue.light} textAlign={{ sm: "start", md: "center" }}>
                    {description}
                  </Typography>
                </Stack>
              </Grid>
              ))}
          </Grid>
          <Box display="flex" justifyContent="center" pt={{ xs: 3.5, lg: 2 }}>
            <Box>
              <Grid container spacing={{xs: 2, sm: 4}} alignItems="center">
                {toolsItems.map(({ title, icon }) => (
                  <Grid key={title} item xs={3}>
                    <Stack spacing={0.5} alignItems="center" justifyContent="center">
                      <Box width={60} height={60} display="flex" justifyContent="center" sx={{ backgroundColor: theme.palette.colors.green.light, borderRadius: 10, padding: 1.5 }}>
                        <img width="100%" alt="enhance" src={icon} />
                      </Box>
                      <Typography textAlign="center">
                        {title}
                      </Typography>
                    </Stack>
                  </Grid>
              ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container pt={{ xs: 4, md: 7, lg: 9.2 }} pb={{ xs: 12, lg: 15 }}>
        <Grid container pb={0.6} justifyContent="center">
          <Typography variant="h3" fontWeight={700}>
            <Trans>
              Our proposal, your benefits
            </Trans>
          </Typography>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          {itemCards.map((item, index) => (
            <Grid
              item
              key={item.title}
              xs={12}
              sm={6}
              lg={3}
              sx={{
              transform: { xs: "none", sm: `translate(0, ${index % 2 === 0 ? 0 : 25}px)` },
              marginLeft: "0 !important",
            }}
          >
              {renderItem({ ...item, isHovered: hoveredCard, setIsHovered: setHoveredCard })}
            </Grid>
        ))}
        </Grid>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject={requestInfoSubject}
        />
      )}
    </ThemeProvider>
  );
}

export default DueDiligenceHome;
