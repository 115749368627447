import React from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import logoNegative from "../images/logo-negative.png";
import Container from "./Container";

function Header() {
  return (
    <Container pt={4}>
      <Link className="logo" to="/">
        <img
          alt="allfunds_logo"
          src={logoNegative}
        />
      </Link>
    </Container>
  );
}

export default Header;
